/*
* constants.module.scss has name element "module" to allow import in react components
* to access variables that are defined for css, which are marked to be exported at the end of the file.
* This is a SASS feature.
* 
* All constants should use !default to allow customization in sub-projects.
*
* !default means: all elements are used as default, as long the constant is not already defined
* to redefine those constants define/import them BEFORE this file is imported into the index.scss,
* especially in the _custom_constants.scss
*/

// import customized constants to overwrite following default definitions for customization
// import must happen before the default definitions to give customized constants priority
@import "_custom_constants.scss";


// default constants

$color-primary: #FF9235 !default;
// color-primary-gradient only for gradient backgrounds
$color-primary-gradient: #FFEC60 !default;
$color-primary-light: #FFBE85 !default;
$color-dark: #292929 !default;
$color-on-primary: $color-dark !default;
$color-white: #ffffff !default;
$color-gray: #595959 !default;
$color-gray-medium: #979797 !default;
$color-gray-light: #D4D4D4 !default;
$color-gray-lighter: #F3F3F3 !default;
$color-green: #7CBF49 !default;
$color-blue: #0092FF !default;
$color-yellow: #FFC107 !default;
$color-red: #F03737 !default;

//default gradient
$background-image: radial-gradient(circle at top left, $color-primary, $color-primary-gradient) !default;

$theme-colors: (
  "primary": $color-primary,
  "primary-gradient": $color-primary-gradient,
  "on-primary": $color-on-primary,
  "gray": $color-gray,
  "gray-medium": $color-gray-medium,
  "gray-light": $color-gray-light,
  "gray-lighter": $color-gray-lighter,
  "dark": $color-dark,
  "white": $color-white,
  "green": $color-green,
  "blue": $color-blue,
  "yellow": $color-yellow,
  "red": $color-red,
  ) !default;

$font-size-xs: 0.75rem !default;

$font-heading: "Montserrat",
  sans-serif !default;
$font-body: "Roboto",
  sans-serif !default;

$container-width: 1800px !default;

$small-round-border: 10px !default;

$box-shadow-default: 0 6px 25px 1px rgba(0, 0, 0, 0.25) !default;
$box-shadow-menu: 0 3px 6px #00000029 !default;
$box-shadow-focused: inset 0px 0px 0px 3px $color-primary !default;

//media screen breakpoints
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$xxxl: 2000px;

// exports single elements to allow importing into react components
:export {
  // export $color-white with export name "white"
  white: $color-white;
}