@import "../../node_modules/react-datepicker/dist/react-datepicker";

.react-datepicker-wrapper {
  display: block;
}

// selected day: default was color-primary on black – if customer color-primary is dark, it's not readable 
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $color-primary;
  color: $color-on-primary;

  &:hover {
    background-color: $color-primary;
  }
}

// fixes postition of small triange showing the datepicker
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: -17px;
}

/* #region datepicker-all-classes */
//see doc/styles.md add keyword "overwritten-external-styles" for more information

/*.react-datepicker__year-read-view--down-arrow
.react-datepicker__month-read-view--down-arrow
.react-datepicker__month-year-read-view--down-arrow
.react-datepicker__navigation-icon
.react-datepicker-popper
.react-datepicker-popper[data-placement^=bottom] 
.react-datepicker-popper[data-placement^=top] 
.react-datepicker-wrapper
.react-datepicker
.react-datepicker__time
.react-datepicker--time-only
.react-datepicker__time-container
.react-datepicker__time-box
.react-datepicker__triangle
.react-datepicker__header
.react-datepicker__header--time
.react-datepicker__header--time--only
.react-datepicker__header--has-time-select
.react-datepicker__year-dropdown-container--select
.react-datepicker__month-dropdown-container--select
.react-datepicker__month-year-dropdown-container--select
.react-datepicker__year-dropdown-container--scroll
.react-datepicker__month-dropdown-container--scroll
.react-datepicker__month-year-dropdown-container--scroll
.react-datepicker__current-month
.react-datepicker-time__header
.react-datepicker-year-header
.react-datepicker__navigation
.react-datepicker__navigation--previous
.react-datepicker__navigation--next
.react-datepicker__navigation--next--with-time
  .react-datepicker__navigation--next--with-today-button
.react-datepicker__navigation--years
.react-datepicker__navigation--years-previous
.react-datepicker__navigation--years-upcoming
.react-datepicker__navigation-icon
.react-datepicker__navigation-icon--next
.react-datepicker__navigation-icon--previous
.react-datepicker__month-container
.react-datepicker__year
.react-datepicker__year-wrapper
.react-datepicker__year-text
.react-datepicker__month
.react-datepicker__month-text
.react-datepicker__quarter-text
.react-datepicker__input-time-container
.react-datepicker-time__caption
.react-datepicker-time__input-container
.react-datepicker-time__input
input
input[type=time]::-webkit-inner-spin-button
input[type=time]::-webkit-outer-spin-button
input[type=time]
.react-datepicker-time__delimiter
.react-datepicker__time-container--with-today-button
.react-datepicker__time-list
.react-datepicker__time-list-item
.react-datepicker__time-list-item--selected
.react-datepicker__time-list-item--disabled
.react-datepicker__week-number
.react-datepicker__week-number--clickable
.react-datepicker__day-names
.react-datepicker__week
.react-datepicker__day-name
.react-datepicker__day
.react-datepicker__time-name
.react-datepicker__month--selected
.react-datepicker__month--in-selecting-range
.react-datepicker__month--in-range
.react-datepicker__quarter--selected
.react-datepicker__quarter--in-selecting-range
.react-datepicker__quarter--in-range
.react-datepicker__month--disabled
.react-datepicker__quarter--disabled
.react-datepicker__day--today
.react-datepicker__month-text--today
.react-datepicker__quarter-text--today
.react-datepicker__year-text--today
.react-datepicker__day--highlighted
.react-datepicker__month-text--highlighted
.react-datepicker__quarter-text--highlighted
.react-datepicker__year-text--highlighted
.react-datepicker__day--highlighted-custom-1
.react-datepicker__month-text--highlighted-custom-1
.react-datepicker__quarter-text--highlighted-custom-1
.react-datepicker__year-text--highlighted-custom-1
.react-datepicker__day--highlighted-custom-2
.react-datepicker__month-text--highlighted-custom-2
.react-datepicker__quarter-text--highlighted-custom-2
.react-datepicker__year-text--highlighted-custom-2
.react-datepicker__day--selected
.react-datepicker__day--in-selecting-range
.react-datepicker__day--in-range
.react-datepicker__month-text--selected
.react-datepicker__month-text--in-selecting-range
.react-datepicker__month-text--in-range
.react-datepicker__quarter-text--selected
.react-datepicker__quarter-text--in-selecting-range
.react-datepicker__quarter-text--in-range
.react-datepicker__year-text--selected
.react-datepicker__year-text--in-selecting-range
.react-datepicker__year-text--in-range
.react-datepicker__day--keyboard-selected
.react-datepicker__month-text--keyboard-selected
.react-datepicker__quarter-text--keyboard-selected
.react-datepicker__year-text--keyboard-selected
.react-datepicker__month--selecting-range
.react-datepicker__day--disabled
.react-datepicker__month-text--disabled
.react-datepicker__quarter-text--disabled
.react-datepicker__year-text--disabled
.react-datepicker__input-container
.react-datepicker__year-read-view
.react-datepicker__month-read-view
.react-datepicker__month-year-read-view
.react-datepicker__year-read-view--down-arrow
.react-datepicker__month-read-view--down-arrow
.react-datepicker__month-year-read-view--down-arrow
.react-datepicker__year-dropdown
.react-datepicker__month-dropdown
.react-datepicker__month-year-dropdown 
.react-datepicker__year-dropdown--scrollable
.react-datepicker__month-dropdown--scrollable
.react-datepicker__month-year-dropdown--scrollable
.react-datepicker__year-option
.react-datepicker__month-option
.react-datepicker__month-year-option
.react-datepicker__year-option--selected
.react-datepicker__month-option--selected
.react-datepicker__month-year-option--selected
.react-datepicker__close-icon
.react-datepicker__today-button
.react-datepicker__portal
.react-datepicker__day-names
*/

/* #endregion datepicker-all-classes */