.dashboard-flex-layout {
  overflow: visible;
  width: 100%;
}

.dashboard-card {
  @include card;

  border-radius: 6px;
  border: 1px solid $color-gray-light;
  box-shadow: $box-shadow-default;
  background-color: $color-white;
  width: 300px;
  padding: 1rem 0 1.5rem 0;
  padding-bottom: 1.5rem;
  text-align: center;
  margin: 1rem;
  position: relative;
  float: left;
  flex-grow: 1;

  // @todo: prüfen ob diese Klasse auch außerhalb von dashboard-card Verwendung findet
  .header {
    border-bottom: 1px solid $color-gray-light;

    svg {
      margin-right: 0.5em;
      margin-top: -0.2em;
    }
  }

  &__body {
    padding: 1rem 2rem;
  }

  &__data {
    font-size: 2rem;
  }

  &--large {
    flex-grow: 2;

    @media screen and (min-width: $md) {
      width: 640px;
    }
  }
}

.statistics-wheel {

  &__outer {
    stroke: $color-primary;
    fill: none;
  }

  &__inner {
    stroke: $color-gray-lighter;
    fill: none;
  }
}