// @todo Phillipp: Linda hat die Klasse .flyout-container hinzugefügt. Braucht es da die ID im Code noch?
.flyout-container {
  background-color: $color-white;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding: 0rem;
  align-items: center;
  position: relative;
  flex-grow: auto;
  width: 100vw;

  @media screen and (min-width: $md) {
    width: 23.75rem;
  }
}

.flyout-fixed {
  display: flex;
  flex-direction: row;
  top: 0rem;
  position: sticky;
  overflow: hidden;
  box-sizing: border-box;
  border-left: 0.0625rem solid #979797;

  width: inherit;
  min-width: inherit;
  height: 100vh;
  background-color: inherit;
}

.flyout-close-icon {
  position: fixed;
  top: 1.3rem;
  right: 1rem;
  display: block;
  z-index: 999999;
}