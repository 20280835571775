// REGULAR

@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Thin.ttf");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Light.ttf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

// ITALIC

@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("/fonts/roboto/Roboto-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}
