// @todo: needs mobile styling (size smaller)
.icon-filteritem-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* unused styles for unused MarketResultsTab.tsx
// @todo: wo sollen solche styles hin? styles-graveyard.scss wurde schon mal angelehnt
  .market-tab+.market-tab {
    margin-left: 0.5rem;

    @media screen and (min-width: $sm) {
      margin-left: 1.25rem;
    }

    @media screen and (min-width: $md) {
      margin-left: 2rem;
    }

    @media screen and (min-width: $xl) {
      margin-left: 5rem;
    }
  } */

.marketplace-search-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;


  .marketplace-search {
    background-color: white;
    box-shadow: $box-shadow-default;
    padding: 1.5rem 2rem 1.5rem 3rem;
    border-radius: 9999px;
    font-family: $font-heading;
    font-weight: 500;

    &::placeholder {
      opacity: 0.75;
    }

    &:focus-visible {
      @include focus-visible--forced;
    }
  }

  .icon {
    position: absolute;
    left: 1rem;
    cursor: pointer;
  }
}

.project-filtering {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__collapse-show {
    max-width: 80%;
  }

  &__btn {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  &--active svg {
    transform: rotate(180deg);
  }
}

// container for meta-information about the platform
// @todo: Kann man das auflösen?
.marketplace-stats {

  @media screen and (max-width: $sm) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .btn {
      flex-grow: 1;
      margin: 0.5rem 1rem;
      max-width: fit-content;
    }
  }
}

// count of users + projects in marketplace header
.stats-view {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  font-family: $font-heading;

  &--count {
    font-size: 1.5rem;
  }
}

.market-info-card {
  background-color: rgba(255, 255, 255, 0.75);
  transition: 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: $background-image;
    border-radius: 0.625rem;
  }
}