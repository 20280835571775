// spinner-border comes from bootstrap
// we overwrite spinner-border styles here, when a spinner is attached to specific platform-elements

.col>.spinner-border,
.card .spinner-border {
  border-color: $color-primary;
  height: 4rem;
  width: 4rem;
  border-width: 0.5rem;
  border-right-color: transparent;
  align-self: center;
  display: block;

  &:not(.btn .spinner-border, .icon-navigation .spinner-border) {
    margin: auto auto 1rem;
  }
}

.btn .spinner-border,
.icon-navigation .spinner-border {
  height: 1rem;
  width: 1rem;
  border-width: 0.125rem;
  border-color: $color-dark;
  border-right-color: transparent;
  margin: 0 0 0 0.5rem;
}

.primary-btn .spinner-border {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 0.175rem;
  margin-left: 0.75rem;
  margin-right: -0.5rem;
}

.page-navigation-btn .spinner-border {
  border-color: $color-primary;
  border-right-color: transparent;
}