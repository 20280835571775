// REGULAR

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Thin.ttf");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ExtraLight.ttf");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Light.ttf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

// ITALIC

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ExtraLightItalic.ttf");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-SemiBoldItalic.ttf");
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf");
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/montserrat/Montserrat-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}
