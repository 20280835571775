/* empty file, required for the import from index.scss,
   overwritten by customized builds for customer specific individualizations */

// start custom frontpage
.frontpage {


  //different logo on frontpage than on plattform + mobile
  header>.row .header-logo {
    background-image: url("/assets/img/logo-frontpage-mobile.svg");

    @media screen and (min-width: $lg) {
      width: 140px;
      height: 54px;
      background-image: url("/assets/img/logo-frontpage.svg");
    }
  }
}

// end custom frontpage