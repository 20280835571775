.process-challenge-list-item {

  &__logo {
    flex-basis: auto;
    float: right;
    width: fit-content;
    height: fit-content;
    max-width: fit-content;
    max-height: fit-content;
  }

  &__text {
    margin: auto 0 auto 0;
  }
}