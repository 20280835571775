@import "../node_modules/rc-slider/assets/index.css";

.rc-slider {
  &-disabled {
    pointer-events: none;
    background-color: transparent;
  }

  & &-step,
  &-rail,
  &-track {
    height: 8px;
  }

  & &-track {
    background-color: $color-primary;
  }


  &-dot {
    height: 10px;
    width: 10px;
    bottom: -1px;

    &-active {
      display: none;
    }
  }


  & &-handle {
    height: 16px;
    width: 16px;
    border-color: $color-gray-light;
    margin-top: -4px;
    box-shadow: none;

    &:hover {
      border-color: $color-dark;
    }

    &:focus,
    &-click-focused:focus {
      border-color: $color-dark;
    }
  }

  & &-mark {
    top: 22px;
  }

  & &-mark-text {
    color: $color-dark;
    width: inherit;

    &:first-child {
      transform: none !important;
      text-align: left;
    }

    &:last-child {
      transform: translateX(-100%) !important;
      text-align: right;
    }

    &:not(:first-child):not(:last-child) {
      @media (max-width: $md) {
        display: none;
      }
    }
  }
}