@import "../../node_modules/react-toastify/dist/ReactToastify";

/**
 * Leave some space for the header navigation
 */
.Toastify__toast-container--top-right {
  top: 6em;
}

.Toastify__toast {
  border-radius: 0.5rem;
  background: white;
  color: $color-dark;
  border-left: 12px solid;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  box-shadow: 0 6px 25px 1px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: $font-size-sm;
  font-family: $font-heading;
  word-break: break-word;
}

.Toastify__toast--success {
  border-left-color: $green;
  color: darken($green, 15%);
}

.Toastify__toast--warning {
  border-left-color: $yellow;
  color: darken($yellow, 15%);
}

.Toastify__toast--error {
  border-left-color: $red;
  color: darken($red, 15%);
}

.Toastify__toast--info {
  border-left-color: $blue;
  color: darken($blue, 15%);
}

.Toastify__toast--default {
  border-left-color: #c1c1c1;
  color: $color-dark;
}

.Toastify__close-button {
  color: $color-dark;
}

.Toastify__close-button--default {
  opacity: 0.7;
}

.Toastify__progress-bar {
  background: $color-primary;
}

.Toastify__progress-bar--default {
  background: $color-primary;
}