/* #region common projects card */
.project-card {

  .card-header {
    display: block;
  }

  &__thumbnail {
    margin: -1.75rem -1.5rem 1.5rem -1.5rem;
    border-bottom: 1px solid $color-gray-light;
    height: 12rem;
    width: auto;
    border-radius: 0.563rem 0.563rem 0 0;
    overflow: hidden;

    // next/image produces an inner span, which styles must be neutralized/unset, when the next/image component gets the needed property layout=”fill”
    // NOTE: rework when updating from next 12 to higher versions! next/image will change its behaviour:
    // styles get more important than component properties like layout=”fill”.
    span {
      position: relative !important;
      overflow: visible !important;

      img {
        width: 100% !important;
        height: auto !important;
        min-width: unset !important;
        max-width: unset !important;
        min-height: unset !important;
        max-height: 12rem !important;
        object-fit: cover !important;
        top: 6rem !important;
      }

      // for Safari only
      @media screen and (-webkit-min-device-pixel-ratio: 0) {

        _::-webkit-full-page-media,
        _:future,
        :root,
        img {
          top: 12rem !important;
        }
      }
    }
  }

  .dropdown {
    display: flex;
  }

  .card-footer {
    border: none;
    background: transparent;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 1rem;
  }

  &--empty {
    border: 3px dashed #cccccc;
    box-shadow: none;

    .card-body {
      display: flex;
      flex-grow: 1;
      margin-bottom: 2rem;
      padding: 0;
      font-size: 1.125rem;
      font-family: $font-heading;
      font-style: italic;
      font-weight: 400;
    }
  }
}

/* #endregion common projects card */

/* #region team-video */

.teamvideo {
  display: flex;
  justify-content: center;
}

/* #endregion team-video */

/* #region followership */

.followership-card-layout {
  display: flex;
  gap: 0.2rem 1rem;
  flex-wrap: wrap;
}

.followership-card {
  padding: 0px;

  &__body {
    padding: 0.5rem !important;
  }

  .round-icon-btn {
    margin-left: 0.5rem;
    scale: 0.8;
  }
}

/* #endregion followership */