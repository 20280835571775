// used in FAQPopup & FeedbackPostPopup
.popup__hr {
  position: absolute;
  height: 7px;
  background-image: $background-image;
  left: 0;
  width: 100%;
  border: 0;
}

.popover,
.modal-content {
  box-shadow: $box-shadow-default;
  border-radius: 0.625rem;
  border: none;
  padding: 0 1.5rem;
  font-family: $font-body;

  .arrow::before {
    border-right-color: transparent;
  }

  .popover-header,
  .modal-header {
    background: none;
    border: none;
    padding-top: 1.75rem;
  }

  .modal-title,
  h3.popover-header {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .form-group {
    label {
      font-weight: 400;
      font-size: 1rem;
    }
  }
}