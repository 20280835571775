html {
  // p font-size default 1rem (16px) becomes 0.875rem (14px) for mobile = 87,5%, other font-sizes are adapted to this)
  font-size: 87.5%;

  @media screen and (min-width: $lg) {
    font-size: 100%;
  }
}

body {
  margin: 0;
  overflow: hidden;
  word-wrap: break-word !important;
}

h1,
h2,
h3 {
  font-family: $font-heading;
}

h1 {
  font-size: 2.5rem;
}

h4,
h5,
h6 {
  font-family: $font-body;
}

h3,
h3>span {
  font-size: 1.75rem !important;
  font-weight: bold;
  word-break: break-word;
  color: $color-dark !important;
}

.font-heading {
  font-family: $font-heading;
}

/* #region title-counter */

// is counting and adding title numbers
// used for numbered lists, e.g. data protection information
.title-counter {
  counter-reset: h1counter;

  h1 {
    counter-reset: h2counter;
  }

  h2 {
    counter-reset: h3counter;
  }

  h1::before {
    content: counter(h1counter) ".\0000a0";
    counter-increment: h1counter;
  }

  h2::before {
    content: counter(h1counter) "." counter(h2counter) ".\0000a0";
    counter-increment: h2counter;
  }

}

/* #endregion  title-counter */

svg {
  fill: currentColor;
}

// some upcoming styles are set to important so that bootstrap style is overwritten @todo: delete as soon as bootstrap import is deleted
.font-weight-bold {
  font-weight: 700 !important;
}

/* #region  text-alignments */
// text-alignment also works for elements that are not text

.text-justify {
  text-align: justify !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/* #endregion  text-alignments */

.text-gray {
  opacity: 0.325;
}

textarea {
  // @todo: diesen Kommentar prüfen. Frieder, Norbert und ich sind für resizable textareas. Man müsste sich alle Nutzungen der Textarea mal in Ruhe anschauen, wie die sich verhalten, wenn sie jetzt plötzlich ihre Größe ändern.
  // let's make textarea not resizeable, therefor we have to define overflow with any unlike "visible"
  resize: none;
  overflow: auto;
  // the textarea shall be drawn above the informationbox of the textlength, otherwise the bottom border of the textarea will not be visible
  position: relative;
  z-index: 2;
}

// styles for the text length information of a textarea
.textlength {
  font-size: 0.8em;
  line-height: 0.8em;
  padding: 1.2em 0.7em 0.7em 0.7em;
  margin-top: -0.5em;
  z-index: 1;
  position: relative;
  background-color: $color-gray-lighter;
}

// used to show info texts, e.g. HiddenOnMobile
.text-info-container {
  max-width: 400px;
  align-self: center;
  color: $color-blue;
  text-align: center;
  border: 1px solid $color-blue;
  border-radius: 12px;
  padding: 1rem;
}

ul {

  li {
    margin-bottom: 0.5rem;
  }

  // for lists without bullets
  &.cube-bullets {
    /* Remove bullets */
    list-style-type: none;
    padding: 0 0 0 2.2rem;

    li::before {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-image: url("/assets/icons/project.svg");
      left: 1rem;
      scale: 80%;

      @media screen and (min-width: $lg) {
        scale: 100%;
      }
    }

    svg {
      scale: 80%;

      @media screen and (min-width: $lg) {
        scale: 100%;
      }
    }
  }

  &.check-bullets {
    list-style-type: "✓";
  }
}

// new line to html-br
.nl2br {
  white-space: pre-line;
}

.optional-on-mobile {
  display: none;

  @media screen and (min-width: $lg) {
    display: table-cell;
  }
}

//only for elements, not for text
.align-right {
  display: flex;
  justify-content: right;
}

.align-center {
  display: flex;
  justify-content: center;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  pointer-events: none;
}

.draggable {
  cursor: move;
}

// editable is usable for different elements, to change the mouse-pointer and colors
.editable {
  &:hover {
    background: $color-white !important;
    color: $color-primary !important;
    cursor: url('/assets/icons/pencil.svg') 0 15, auto !important;
  }
}

.infinite-scroll-component {
  overflow: visible !important;

  &--table {
    overflow: hidden !important;
  }
}

.idea-report {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  display: flex;
  justify-content: flex-end;

  .actionicon {
    opacity: 0.5;

    &:hover {
      opacity: 0.75;
      transform: rotate(-5deg);
    }
  }
}

.dancing {
  transition: all 0.2s ease-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: $box-shadow-lg;
  }
}

// @todo Linda: braucht es diese Zeilen? 
.card-header+.card-body,
.collapse>.card-body,
.collapsing>.card-body {
  padding-top: 0;
}