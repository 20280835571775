.extended-category {
  &__title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    h3 {
      margin-right: 1rem;
    }
  }
}