@font-face {
  font-family: "Caveat";
  src: url("/fonts/caveat/Caveat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Caveat";
  src: url("/fonts/roboto/Caveat-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Caveat";
  src: url("/fonts/caveat/Caveat-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Caveat";
  src: url("/fonts/caveat/Caveat-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}