.language-selector {
  background-color: $color-white;
  border: solid 1px $color-dark;
  border-radius: 9px;
  padding: 0.4rem 0.6rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  max-width: fit-content;
  margin: auto;

  svg {
    color: inherit;
  }

  &:hover {
    color: $color-dark;
    background-color: $color-gray-lighter;
  }
}

.language-selector__dropdown {
  text-align: right;

  // prevents flickering of text from black to orange to black
  .btn:hover {
    color: inherit !important;
  }

  @media screen and (max-width: $sm) {
    text-align: center;
  }

  .dropdown-menu {
    background-color: #ffffff;
    background-image: none;
    box-shadow: none;
    min-width: 0;
    padding: 0.4rem 0.6rem;
    right: 13px;
    bottom: 45px;
    border: solid 1px $color-dark;
    border-radius: 9px;
    // inline styles, that need to be overwritten
    top: unset !important;
    left: unset !important;
    transform: none !important;

    @media screen and (max-width: $sm) {
      right: 0;
      bottom: 38px;
      width: fit-content;
      margin: 0 auto;
      left: 0 !important;
    }
  }

  .dropdown-menu::before {
    content: none;
  }

  .dropdown-menu::after {
    position: absolute;
    content: "";
    background-image: url('/assets/img/language-selector__triangle.svg');
    bottom: -9px;
    right: 6px;
    width: 20px;
    height: 10px;
    border: none;

    @media screen and (max-width: $sm) {
      right: unset;
      margin-left: -10px;
    }

  }

  .dropdown-menu .dropdown-item {
    padding: 0.4rem;

    * {
      padding: 0;
    }

    &:hover {
      background-color: $color-gray-lighter;
    }
  }
}