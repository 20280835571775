// used in EventCard.tsx
.event-card {
  max-width: 315px;

  &__header {
    padding-bottom: 1rem;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    margin-top: 0 !important;
  }

  &__location {
    margin-bottom: 1rem;
  }

  &__button {
    align-self: flex-end;
  }
}

.event-card-layout {
  display: flex;
}