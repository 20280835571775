@import "../../node_modules/react-quill/dist/quill.snow";

/**
 * Styles for the rich text editor (currently Quill)
 */
.quill {
  display: block;
}

.ql-editor {
  background-color: $color-gray-lighter;
}

.ql-container {
  height: auto !important;
}

.rte-container {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: $color-gray-lighter;

  .ql-container.ql-snow {
    font-size: 15px;
    border: none;
  }

  .ql-toolbar.ql-snow {
    border: none;
    background: $color-gray-lighter;
    color: white;
    border-bottom: 2px solid black;
    padding-top: 0px;
    padding-bottom: 3px;
    margin-bottom: 5px;
  }

  .ql-snow .ql-picker {
    border: 1px solid black;
    border-radius: 3px;
  }
}

// bar below the editor showing the number of current characters
.ql-textlength {
  font-size: 0.8em;
  line-height: 0.8em;
  margin-top: 0.7em;
}

/* fix multi-line placeholder overflow */
.ql-editor.ql-blank::before {
  content: none;
}

.ql-editor.ql-blank::after {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  display: block;
  font-style: italic;
  margin-top: -1.42em;
  min-height: 1em;
  pointer-events: none;
}

// for displaying a number next to a title
.number-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  //@todo Linda: prüfen, ob man rte-content auflösen kann (beide Instanzen in dieser Datei), da dies unsere eigenen Styles sind
  .rte-content {
    margin: 0em;
    padding-left: 1rem;
  }
}

// for displaying the formatted content
.rte-content {
  margin-bottom: 1rem;

  p {
    margin: 0
  }

  .ql-size-small {
    font-size: 0.8em;
  }

  .ql-size-large {
    font-size: 1.4em;
  }

  .ql-size-huge {
    font-size: 2em;
  }
}